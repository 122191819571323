html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 1.42857;
  min-height: 100%;
  font-weight: 300;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px 0;
  font-weight: 300;
}

label {
  font-weight: 600;
  margin: 0;
}

input,
select,
label,
button,
textarea {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.875rem;
}

p,
a {
  font-weight: 300;
}

ul {
  list-style: none;
  padding: 0;

}

strong {
  font-weight: bold;
}

@media (min-width: 641px) {
  .show-on-mobile {
    display: none !important;
  }
}

@media (max-width: 641px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/*###########################################################################
# SCROLBAR
#############################################################################*/

.fancy-scrollbar::-webkit-scrollbar {
  width: 7.5px;
}

.fancy-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

.fancy-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/*###########################################################################
# GRID STRUCTURE
#############################################################################*/

.grid-block,
.grid-block-center {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.grid-block-center {
  align-items: center;
}

[class*="grid-block"] {
  align-items: center;
}

[class*="grid-block"] .span-sm-1 {
  grid-column: span 1;
}

[class*="grid-block"] .span-sm-2 {
  grid-column: span 2;
}

[class*="grid-block"] .span-sm-3 {
  grid-column: span 3;
}

[class*="grid-block"] .span-sm-4 {
  grid-column: span 4;
}

[class*="grid-block"] .span-sm-5 {
  grid-column: span 5;
}

[class*="grid-block"] .span-sm-6 {
  grid-column: span 6;
}

[class*="grid-block"] .span-sm-7 {
  grid-column: span 7;
}

[class*="grid-block"] .span-sm-8 {
  grid-column: span 8;
}

[class*="grid-block"] .span-sm-9 {
  grid-column: span 9;
}

[class*="grid-block"] .span-sm-10 {
  grid-column: span 10;
}

[class*="grid-block"] .span-sm-11 {
  grid-column: span 11;
}

[class*="grid-block"] .span-sm-12 {
  grid-column: span 12;
}

@media only screen and (min-width: 481px) {
  [class*="grid-block"] .span-sm-1 {
    grid-column: span 1;
  }

  [class*="grid-block"] .span-sm-2 {
    grid-column: span 2;
  }

  [class*="grid-block"] .span-sm-3 {
    grid-column: span 3;
  }

  [class*="grid-block"] .span-sm-4 {
    grid-column: span 4;
  }

  [class*="grid-block"] .span-sm-5 {
    grid-column: span 5;
  }

  [class*="grid-block"] .span-sm-6 {
    grid-column: span 6;
  }

  [class*="grid-block"] .span-sm-7 {
    grid-column: span 7;
  }

  [class*="grid-block"] .span-sm-8 {
    grid-column: span 8;
  }

  [class*="grid-block"] .span-sm-9 {
    grid-column: span 9;
  }

  [class*="grid-block"] .span-sm-10 {
    grid-column: span 10;
  }

  [class*="grid-block"] .span-sm-11 {
    grid-column: span 11;
  }

  [class*="grid-block"] .span-sm-12 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 641px) {
  [class*="grid-block"] .span-md-1 {
    grid-column: span 1;
  }

  [class*="grid-block"] .span-md-2 {
    grid-column: span 2;
  }

  [class*="grid-block"] .span-md-3 {
    grid-column: span 3;
  }

  [class*="grid-block"] .span-md-4 {
    grid-column: span 4;
  }

  [class*="grid-block"] .span-md-5 {
    grid-column: span 5;
  }

  [class*="grid-block"] .span-md-6 {
    grid-column: span 6;
  }

  [class*="grid-block"] .span-md-7 {
    grid-column: span 7;
  }

  [class*="grid-block"] .span-md-8 {
    grid-column: span 8;
  }

  [class*="grid-block"] .span-md-9 {
    grid-column: span 9;
  }

  [class*="grid-block"] .span-md-10 {
    grid-column: span 10;
  }

  [class*="grid-block"] .span-md-11 {
    grid-column: span 11;
  }

  [class*="grid-block"] .span-md-12 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1025px) {
  [class*="grid-block"] .span-lg-1 {
    grid-column: span 1;
  }

  [class*="grid-block"] .span-lg-2 {
    grid-column: span 2;
  }

  [class*="grid-block"] .span-lg-3 {
    grid-column: span 3;
  }

  [class*="grid-block"] .span-lg-4 {
    grid-column: span 4;
  }

  [class*="grid-block"] .span-lg-5 {
    grid-column: span 5;
  }

  [class*="grid-block"] .span-lg-6 {
    grid-column: span 6;
  }

  [class*="grid-block"] .span-lg-7 {
    grid-column: span 7;
  }

  [class*="grid-block"] .span-lg-8 {
    grid-column: span 8;
  }

  [class*="grid-block"] .span-lg-9 {
    grid-column: span 9;
  }

  [class*="grid-block"] .span-lg-10 {
    grid-column: span 10;
  }

  [class*="grid-block"] .span-lg-11 {
    grid-column: span 11;
  }

  [class*="grid-block"] .span-lg-12 {
    grid-column: span 12;
  }
}

/*###########################################################################
# SWEET ALERT
#############################################################################*/
#swal2-html-container {
  color: #4D4D4D;
  font-weight: bold;
  width: 100%;
  margin: 20px 0 0 0;
  padding: 0 35px;
}

.swal2-icon.swal2-warning {
  border-color: #004fea !important;
  color: #004fea !important;
}

#swal2-title {
  font-size: 20px;
}

label.swal2-checkbox {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0 20px;
}

span.swal2-label {
  font-size: 14px;
  font-weight: 500;
}

#swal2-checkbox {
  outline: none !important;
}

.bonus-box {
  margin: 20px 0 !important;
  transition: all 0.5s ease-out 0s !important;
  font-family: Montserrat, sans-serif !important;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(151, 151, 151);
  color: rgb(77, 77, 77) !important;
  font-size: 0.8125rem !important;
  border-radius: 32px !important;
  padding: 10px 15px !important;
  font-weight: 400 !important;
  display: block !important;
  outline: none !important;
  width: 100% !important;
}

.bonus-box#url-input {
  border: 1px solid rgb(151, 151, 151) !important;
}

.bonus-box#points {
  border-radius: 5px !important;
  border: 1px solid #d9d9d9;
}

.bonus-box:focus {
  box-shadow: none !important;
}

select.bonus-box {
  margin-bottom: 0px !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-image: initial !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 10px 0px !important;
  font-weight: 500 !important;
}

#swal2-html-container span {
  font-size: 12px;
  font-weight: 500;
}

.bonus-button {
  background: #d113fe !important;
  border: 1px solid #d113fe !important;
  border-radius: 30px !important;
  font-weight: 900 !important;
}

.hide {
  display: none !important;
}

.swal2-modal .custom-swal-margin {
  margin: 1em 2em 3px;
}

.textArea-bonus {
  margin-top: 10px;
  resize: none !important;
  padding: 0.75em;
  width: 100%;
  transition: border-color .1s,box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: 0 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%), 0 0 0 3px transparent;
  color: inherit;
}

.textArea-bonus:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%), 0 0 0 3px rgb(100 150 200 / 50%);
}

.swal2-textarea {
  resize: none !important;
}

.swal2-styled {
  border-radius: 30px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #eb00ff !important;
  font-weight: bold !important;
}

.swal2-textarea {
  resize: none !important;
}

.swal2-styled {
  border-radius: 30px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #eb00ff !important;
  font-weight: bold !important;
}

.swal2-actions button {
  width: 100px;
}

#swal2-html-container .password-label {
  display: block;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
}

#swal2-html-container .password-input {
  margin: 10px 0 20px !important;
}

.ReactModal__Content--after-open{
  width: 32em;
  max-width: 100%;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(0,0,0,.4) !important;
  z-index: 9999;
}

.flex-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}